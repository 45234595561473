import * as Sentry from '@sentry/browser';

if (process.env.sentryDSN) {
  Sentry.init({
    dsn: process.env.sentryDSN,
    environment: process.env.homepassEnvironment,
    enabled:
      ['production', 'staging', 'sandbox'].indexOf(
        process.env.environment || 'unknown',
      ) !== -1,
  });
}

export function captureException(error: any, debugMessage: string) {
  if (process.env.isDev) {
    // eslint-disable-next-line no-console
    console.error(debugMessage, error);
  } else {
    Sentry.captureException(error);
  }
}
